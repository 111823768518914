import React, { Component } from "react";
import styled from "styled-components";
import { StaticImage } from "../Components/StaticImage";
import {
  HeaderFontCss,
  ParagraphFontCss,
  ButtonFontCss,
} from "../Utils/CssStyleTemplateUtil";
import { s, d } from "../Utils/Lang";

class IndustryItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIdx: null,
    };
  }

  render() {
    let { industries } = this.props;
    let { activeIdx } = this.state;
    let activeItem = activeIdx !== null ? industries[activeIdx] : null;

    return (
      <Wrapper>
        {industries.map((item, idx) => {
          let isActiveItem = activeIdx === idx;

          return (
            <GroupItem
              key={idx}
              active={isActiveItem}
              subItemsCount={
                isActiveItem && activeItem ? activeItem.items.length : 1
              }
              onClick={() =>
                this.setState({ activeIdx: isActiveItem ? null : idx })
              }
            >
              <div className="item-wrapper">
                <div className="icon-image">
                  <StaticImage
                    path={"/industry/"}
                    name={
                      isActiveItem
                        ? `${item.image.split(".")[0]}-2.${
                            item.image.split(".")[1]
                          }`
                        : item.image
                    }
                  />
                </div>
                <p>{s(item.title)}</p>
              </div>
              <div className={`detail-info${isActiveItem ? " show" : ""}`}>
                {item.items.map((subItem, subIdx) => {
                  return (
                    <div className="sub-item-wrapper" key={subIdx}>
                      <div className="sub-item-image-wrapper">
                        <div className="sub-item-image">
                          <StaticImage
                            style={{ width: 100 }}
                            path={"/industry/"}
                            name={subItem.image || "icon-iosapp.png"}
                          />
                        </div>
                      </div>
                      <p>{subItem.title}</p>
                      <span>{subItem.content}</span>
                    </div>
                  );
                })}
              </div>
            </GroupItem>
          );
        })}
      </Wrapper>
    );
  }
}

const GroupItem = styled.div`
  --grid-height: 189px;
  --per-grid-width: 25%;
  margin-bottom: ${props =>
    props.active
      ? `calc(var(--grid-height)*${Math.ceil(props.subItemsCount / 4)})`
      : "0px"}; /* to expand bottom spacing */

  /* to cover on other GroupItem */
  ${props =>
    props.active
      ? `z-index: 1;box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.22);`
      : ""}

  @media screen and (max-width: 600px) {
    --per-grid-width: 50%;
    margin-bottom: ${props =>
      props.active
        ? `calc(var(--grid-height)*${Math.ceil(props.subItemsCount / 2)})`
        : "0px"}; /* to expand bottom spacing */
  }
  width: var(--per-grid-width);
  color: #4e4e4e;
  height: var(--grid-height);
  border: 1px solid #f1f1f1;
  border-left-width: 0px;
  border-top-width: 0px;
  &:nth-child(4n + 1) {
    border-left-width: 1px;
  }
  transition: margin-bottom 0.3s;

  & .item-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    /* to cover on  detail-info's shadow */
    ${props => (props.active ? "z-index: 2; position: relative;" : "")}
    background-color: white;

    & > p {
      font-size: 25px;
      color: #4e4e4e;
      line-height: 23px;
      font-weight: 200;
      letter-spacing: 2px;
      margin-top: 16px;
      ${ParagraphFontCss}
    }

    & .icon-image {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.22);
    }
  }

  & .detail-info {
    display: flex;
    height: 0px;
    opacity: 0;
    background-color: white;
    transition: height, opacity 0.3s;
    width: 100%;
    position: absolute;
    left: 0px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.22);
    border-top: none;
    flex-wrap: wrap;
    overflow: hidden;

    & .sub-item-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      width: var(--per-grid-width);

      & .sub-item-image-wrapper {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        & .sub-item-image {
          width: 100px;
          height: 100px;
        }
      }

      & p {
        font-size: 20px;
        letter-spacing: 1.6px;
        line-height: 23px;
        margin-top: 10px;
        margin-bottom: 15px;
        ${ParagraphFontCss}
      }

      & span {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        ${ParagraphFontCss}
      }
    }
  }

  & .detail-info.show {
    height: ${props =>
      `calc(var(--grid-height)*${Math.ceil(props.subItemsCount / 4)})`};

    @media screen and (max-width: 600px) {
      height: ${props =>
        `calc(var(--grid-height)*${Math.ceil(props.subItemsCount / 2)});`};
    }

    opacity: 1;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export default IndustryItemList;
