import React from "react";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import { StaticImage } from "../Components/StaticImage";
import {
  HeaderFontCss,
  ParagraphFontCss,
  ButtonFontCss,
} from "../Utils/CssStyleTemplateUtil";
import { s, d } from "../Utils/Lang";
import IndustryItemList from "../Components/IndustryItemList";
import { isDesktop, isPhone } from "../Utils/WindowUtil";

const CategoryItems = [
  {
    title: "Web",
    colors: ["#5AE6C2", "#55C2E3"],
    shadowColor: "#59DDCB",
    image: "category-web.png",
  },
  {
    title: "App",
    colors: ["#5ACEED", "#4F68CA"],
    shadowColor: "#5496DA",
    image: "category-app.png",
  },
  {
    title: "IoT",
    colors: ["#C6FFBC", "#16A085"],
    shadowColor: "#42B38F",
    image: "category-iot.png",
  },
  {
    title: "BlockChain",
    colors: ["#96F1D0", "#77BCBD"],
    shadowColor: "#8CE0C9",
    image: "category-blockchain.png",
  },
];

class IndustryPage extends React.Component {
  render() {
    let { industries } = this.props.pageContext;
    let { navActions } = this.props;

    return (
      <Wrapper>
        <div className="introduction-section">
          <h2>{s("industry-title")}</h2>
          <p>{s("industry-description")}</p>
          <br />
          <h2>{s("tech-good")}</h2>
          <h3>Frontend</h3>
          <em>React / React Native</em>
          <br />
          <h3>Backend</h3>
          <em>Python / Node.js / AWS</em>
          <br />
          <h3>IoT</h3>
          <em>Embedded System / Linux Programing</em>
          <br />
          <h3>Connectivity</h3>
          <em>NFC / Bluetooth / BLE</em>
          <br />
          <h3>Blockchain</h3>
          <em>Cold Wallet / Smart Contract</em>
        </div>
        <div className="list-section">
          <div className="content-title">
            <div>{s("industry-content_title_1")}</div>
            <div>{s("industry-content_title_2")}</div>
          </div>

          <div className="items-container">
            {CategoryItems.map(category => {
              return (
                <CategoryItem
                  colors={category.colors}
                  shadowColor={category.shadowColor}
                >
                  <div className="cat-icon-wrapper">
                    <StaticImage
                      style={{ width: 65, height: 65 }}
                      path={"/industry/"}
                      name={category.image}
                    />
                  </div>
                  <p>{category.title}</p>
                </CategoryItem>
              );
            })}
            <div className="seperator-line" />
            <IndustryItemList industries={industries} />
          </div>
        </div>
      </Wrapper>
    );
  }
}

const CategoryItem = styled.div`
  --per-grid-width: 25%;
  @media screen and (max-width: 600px) {
    --per-grid-width: 50%;
  }
  width: var(--per-grid-width);
  height: 229px;
  border: 1px solid #f1f1f1;

  &:not(:first-child) {
    border-left-width: 0px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .cat-icon-wrapper {
    border-radius: 20px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 ${props => props.shadowColor};
    background-image: linear-gradient(
      38deg,
      ${props => props.colors[0]},
      ${props => props.colors[1]}
    );
  }

  & p {
    margin-top: 26px;
    font-size: 30px;
    color: #4e4e4e;
    ${HeaderFontCss}
  }

  @media screen and (max-width: 900) {
    height: 189px;
  }

  @media screen and (max-width: 500) {
    & p {
      font-size: 25px;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  & .seperator-line {
    height: 5px;
    width: 100%;
    background: linear-gradient(90deg, #fdce82 17%, #faa34d 57%, #f36a26 90%);
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;

    & h2 {
      letter-spacing: 2px;
      font-size: 26px;
      ${HeaderFontCss}
      margin-bottom: 20px;
    }

    & p {
      font-size: 14px;
      ${ParagraphFontCss}
      white-space: pre-wrap;
      text-align: justify;
      line-height: 20px;
    }

    & button {
      /* reset default button style */
      border: none;
      &:hover,
      &:focus {
        outline: none;
        border: none;
      }

      cursor: pointer;
      padding: 12px 20px;
      color: white;
      background-color: #f36a26;
      font-size: 20px;
      ${ButtonFontCss}
      margin-top: 40px;
      align-self: flex-start;
    }
  }

  .list-section {
    flex-grow: 1;

    & .content-title {
      display: flex;
      align-items: stretch;
      padding: 28px 50px;
      font-size: 30px;
      color: #4e4e4e;
      letter-spacing: 0.68px;
      flex-wrap: wrap;
      justify-content: center;
      ${HeaderFontCss}
    }
    & .items-container {
      display: flex;
      flex-wrap: wrap;
      /* & .project-item {
        flex-basis: calc(100% / 2);
        cursor: pointer;
      } */
    }
  }

  @media screen and (max-width: 900px) {
    .introduction-section {
      /* turn off inner scroll */
      padding: 30px;
      height: auto;
      flex-basis: initial;

      flex-grow: 1;
      & h2 {
        letter-spacing: 2px;
        text-align: left;
      }

      & button {
        align-self: center;
      }
    }

    .list-section {
    }
  }

  @media screen and (max-width: 500px) {
    .logo-image-container {
      display: none;
    }
    .introduction-section {
      padding: 45px 25px 50px;

      & h2 {
        letter-spacing: 2px;
        font-size: 26px;
        margin-bottom: 15px;
        text-align: left;
      }
      & button {
        margin-top: 30px;
        font-size: 14px;
        align-self: flex-start;
      }
    }
    .list-section {
      & .content-title {
        padding: 10px 15px;
        font-size: 25px;
      }
      & .items-container {
        /* & .project-item {
          flex-basis: 100%;
        } */
      }
    }
  }
`;

export default withPage(connect(null, ActionCreator)(IndustryPage));
